import React, { Component } from 'react';
import { motion } from 'framer-motion';

import { AppContext } from 'context.js'
import { get, post } from 'utils/requests.js'
import { PageTransition } from 'anims.js';
import { Spinner } from 'components/Spinner.js';

import {ReactComponent as GearIcon} from 'img/gear.svg';
import {ReactComponent as Checkmark} from 'img/checkmark.svg';
import {ReactComponent as SpeakerIcon} from 'img/speaker.svg';
import {ReactComponent as SortIcon} from 'img/sort.svg';
import {ReactComponent as UpArrowIcon} from 'img/up-arrow.svg';
import {ReactComponent as DownArrowIcon} from 'img/down-arrow.svg';

import './Stocks.scss';


function round2f(val) {
    return Number(Number(val).toFixed(2))
}


export class Stocks extends Component {
  static contextType = AppContext;
  
  constructor(props) {
      super(props);
      this.nextMarketOpenTS = null;
      this.mainInterval = null;
      this.synth = null;
      this.voices = [];
      
      this.state = {
		  addTickerValue: '',
		  addTickerError: '',
		  addTickerEnabled: true,
          stocksConfig: null,
		  stocksData: null,
		  configMode: false,
		  sortMenuVisible: false,
		  currentSortMode: 'dateAdded',
		  currentSortReverse: false,
      }

      this.sortModes = {
        name: 'Name',
        dateAdded: 'Date added',
        percentChange: '% Change',
        dollarChange: '$ Change',
        price: 'Price',
        volume: 'Volume',
        spread: 'Spread',
        marketCap: 'Market cap',
        shares: 'Share count',
    }
      
      this.latestAlertPrices = {}
      
      this.variants = {
          paneVisible: {
              opacity: 1,
              visibility: 'visible',
              height: 'auto',
              transition: {duration: 0.5},
          },
          paneHidden: {
              opacity: 0,
              visibility: 'hidden',
              height: 0,
              transition: {duration: 0.5},
          },
          stickyFooterVisible: {
              y: 0,
              opacity: 1,
              transition: {duration: 0.5},
          },
          stickyFooterHidden: {
              y: '+100px',
              opacity: 0,
              transition: {duration: 0.5},
          },
          addFormEnabled: {
              opacity: 1,
              scale: 1,
              transition: {duration: 0.2},
          },
          addFormDisabled: {
              opacity: 0.4,
              scale: 0.7,
              transition: {duration: 0.2},
          },
      }
  }
  
  componentDidMount() {
    if ('speechSynthesis' in window) {
        this.synth = window.speechSynthesis;
        this.voices = this.synth.getVoices();
    }
    this.originalWindowTitle = document.title;
    
    if (this.context.authed) {
        this.getStocksConfig();
    }
  }
  
  componentWillUnmount() {
      document.title = this.originalWindowTitle;
      if (this.mainInterval) {
          clearInterval(this.mainInterval);
      }
  }
  
  getStocksConfig = () => {
      // console.log('getStocksConfig')
      
      get('/api/stocks/stocks_config/')
      .then(resp => {
        this.nextMarketOpenTS = resp.next_market_open_ts;
        this.resetConfigState(resp.config);
        
        if (!this.state.stocksData) {
            this.getStocksData();
        }
  		
      })
      .catch(err => {
        this.resetConfigState(null)
      })
  }
  
  getStocksData = () => {
    // console.log('getStocksData')
    if (!this.voices.length) {
        this.voices = this.synth.getVoices();
    }
    
    if (!this.state.stocksConfig) {
        this.setState({
			stocksData: null,
		});
        return;
    }
	
    // Pull price
    get(`/api/stocks/price_data/`)
    .then(resp => {
        // Calculate spread and add it to the ticker data
        if (resp.data && resp.data.tickers) {
            for (let tickerData of Object.values(resp.data.tickers)) {
                tickerData.spread = '-';
                if (tickerData.high && tickerData.low) {
                    tickerData.spread = Number(tickerData.high) - Number(tickerData.low);
                    tickerData.spread = isNaN(tickerData.spread) ? '-' : tickerData.spread.toFixed(2);
                }
            }
        }

		this.setState({
			stocksData: resp.data,
		});
        
        if (!this.mainInterval) {
            var mainInterval = setInterval(() => {
                this.getStocksData();
            }, 2000);
            this.mainInterval = mainInterval;
        }
    })
    .catch(resp => {
        this.setState({
			stocksData: null,
		});
    })
    
    // If its been 6.5 hours since our last known market open, request a new next_market_open_ts
    let curTime = new Date().getTime() / 1000;
    if (this.nextMarketOpenTS && curTime > this.nextMarketOpenTS + 23400) {
        get('/api/stocks/stocks_config/')
        .then(resp => {
            this.nextMarketOpenTS = resp.next_market_open_ts;
        })
        .catch(err => {})
    }
    
  }
  
  resetConfigState = (config) => {
    // console.log('resetConfigState config', config)
    
    let newState = {};
    
    if (config) {
        newState.stocksConfig = config;
        
        newState['form-config-general-show_market_state'] = config.show_market_state;
        newState['form-config-general-show_timestamp'] = config.show_timestamp;
        newState['form-config-general-show_ticker_key_stats'] = config.show_ticker_key_stats;
        newState['form-config-general-show_ticker_extra_details'] = config.show_ticker_extra_details;
        newState['form-config-general-show_ticker_alert_hints'] = config.show_ticker_alert_hints;
        newState['form-config-general-show_regular_market_countdown'] = config.show_regular_market_countdown;
        
        for (const tickerConfig of config.ticker_configs) {
            const ticker = tickerConfig.ticker;
            
            newState[`form-config-ticker-${ticker}-alert`] = tickerConfig.alert;
            newState[`form-config-ticker-${ticker}-alert_step`] = tickerConfig.alert_step;
            newState[`form-config-ticker-${ticker}-alert_speech`] = tickerConfig.alert_speech;
            newState[`form-config-ticker-${ticker}-show_in_title`] = tickerConfig.show_in_title;
            newState[`form-config-ticker-${ticker}-title_format`] = tickerConfig.title_format;
            newState[`form-config-ticker-${ticker}-price_factor`] = tickerConfig.price_factor;
        }
    }
    else {
        newState.stocksConfig = null;
        
        newState['form-config-general-show_market_state'] = null;
        newState['form-config-general-show_timestamp'] = null;
        newState['form-config-general-show_ticker_key_stats'] = null;
        newState['form-config-general-show_ticker_extra_details'] = null;
        newState['form-config-general-show_ticker_alert_hints'] = null;
        newState['form-config-general-show_regular_market_countdown'] = null;
        
        for (const tickerConfig of config.ticker_configs) {
            const ticker = tickerConfig.ticker;
            
            newState[`form-config-ticker-${ticker}-alert`] = null;
            newState[`form-config-ticker-${ticker}-alert_step`] = null;
            newState[`form-config-ticker-${ticker}-alert_speech`] = null;
            newState[`form-config-ticker-${ticker}-show_in_title`] = null;
            newState[`form-config-ticker-${ticker}-title_format`] = null;
            newState[`form-config-ticker-${ticker}-price_factor`] = null;
        }
    }
    
    this.latestAlertPrices = {};
    this.setState(newState)
    
  }
  
  postConfigUpdate = () => {
    // console.log('postConfigUpdate')
    
    let stocksConfigPostData = {
        show_market_state: this.state['form-config-general-show_market_state'],
        show_timestamp: this.state['form-config-general-show_timestamp'],
        show_ticker_key_stats: this.state['form-config-general-show_ticker_key_stats'],
        show_ticker_extra_details: this.state['form-config-general-show_ticker_extra_details'],
        show_ticker_alert_hints: this.state['form-config-general-show_ticker_alert_hints'],
        show_regular_market_countdown: this.state['form-config-general-show_regular_market_countdown'],
        ticker_configs: {},
    };
    
    for (const tickerConfig of this.state.stocksConfig.ticker_configs) {
        const ticker = tickerConfig.ticker;
        stocksConfigPostData.ticker_configs[ticker] = {
            alert: this.state[`form-config-ticker-${ticker}-alert`],
            alert_step: this.state[`form-config-ticker-${ticker}-alert_step`],
            alert_speech: this.state[`form-config-ticker-${ticker}-alert_speech`],
            show_in_title: this.state[`form-config-ticker-${ticker}-show_in_title`],
            title_format: this.state[`form-config-ticker-${ticker}-title_format`],
            price_factor: this.state[`form-config-ticker-${ticker}-price_factor`],
        }
    }
    
    post('/api/stocks/stocks_config/', {
        option: 'update_config',
        stocks_config: stocksConfigPostData,
    })
    .then(resp => {
        // console.log('post resp: ', resp);
        
        if (resp.error) {
    	  	
        }
        else {
            
            this.resetConfigState(resp.config)
            this.setState({configMode: false})
        }
    })
    .catch(err => {
        console.log('post err: ', err);
        
    })
    
      
  }
  
  postAddTicker = () => {
    if (!this.state.addTickerEnabled) return;
    
    this.setState({addTickerEnabled: false, addTickerError: null});
	
    post('/api/stocks/stocks_config/', {
        option: 'add_ticker',
        ticker: this.state.addTickerValue,
    })
    .then(resp => {
        // console.log('post resp: ', resp);
        if (resp.error) {
    	  	this.setState({
                addTickerError: resp.error,
                addTickerEnabled: true,
            })
        }
        else {
            this.setState({
                addTickerValue: '',
                addTickerError: null,
                addTickerEnabled: true,
            })
        }
        this.resetConfigState(resp.config)
    })
    .catch(err => {
        console.log('stocks_config post err:', err);
        this.setState({
            addTickerError: 'error',
            addTickerEnabled: true,
        })
    })
  }
  
  postRemoveTicker = (ticker) => {
	// console.log('postAddTicker', this.state.addTickerValue)
	
    post('/api/stocks/stocks_config/', {
        option: 'remove_ticker',
        ticker: ticker,
    })
    .then(resp => {
        // console.log('post resp: ', resp);
        this.setState({stocksConfig: resp.config})
    })
    .catch(err => {
        console.log('postRemoveTicker err: ', err);
        
    })
  }
  
  testSpeech = (ticker) => {
      if (!this.synth) return;
      
      // msg.rate = 1;
      let alert = this.state[`form-config-ticker-${ticker}-alert`];
      let alertSpeech = this.state[`form-config-ticker-${ticker}-alert_speech`];
      
      if (alert && alertSpeech) {
          let price = '420.69';
          if (this.state.stocksData && this.state.stocksData.tickers && this.state.stocksData.tickers[ticker] && this.state.stocksData.tickers[ticker].price) {
              price = this.state.stocksData.tickers[ticker].price;
          }
          else if (this.latestAlertPrices[ticker]) {
              price = this.latestAlertPrices[ticker]
          }
          
          let volume = '6.9M';
          if (this.state.stocksData && this.state.stocksData.tickers && this.state.stocksData.tickers[ticker] && this.state.stocksData.tickers[ticker].volume) {
              volume = this.state.stocksData.tickers[ticker].volume;
          }
          
          if (!this.voices.length) this.voices = this.synth.getVoices();
          
          let msgText = alertSpeech;
          msgText = msgText.replace(/\{\$\}/g, price || ' ')
          msgText = msgText.replace(/\{volume\}/g, volume || ' ')
          let msg = new SpeechSynthesisUtterance(msgText)
          msg.lang = 'en-US'
          msg.voice = this.voices.find((voice) => voice.lang === 'en-US')
          this.synth.speak(msg);
          // msg.rate = 1;
      }
  }
  
  get_colour_class = (val) => {
      if (Number(val) > 0.00) return 'green';
      else if (Number(val) < 0.00) return 'red';
      return ''
  }

  compareTickerNumber(config1, config2, key) {
    let data1 = this.state.stocksData.tickers[config1.ticker]
    let data2 = this.state.stocksData.tickers[config2.ticker]

    if (!data1 || data1.error || data1[key] === null || data1[key] === '' || data1[key] === '-') {
        return 1
    }
    if (!data2 || data2.error || data2[key] === null || data2[key] === '' || data2[key] === '-') {
        return -1
    }
    
    return this.state.currentSortReverse ? data1[key] - data2[key] : data2[key] - data1[key]
  }
  
  getSortedTickerConfigs = () => {
    let configs = this.state.stocksConfig.ticker_configs

    // Dont be swapping around ticker positions while config menu is open
    if (this.state.configMode) return configs

    // Sort ticker config list in the state
    if (this.state.currentSortMode === 'name') {
        configs.sort((a,b) => 
            this.state.currentSortReverse ? b.ticker.localeCompare(a.ticker, 'en') : a.ticker.localeCompare(b.ticker, 'en')
        )
    }
    else if (this.state.currentSortMode === 'dateAdded') {
        configs.sort((a,b) => 
            this.state.currentSortReverse ? b.created.localeCompare(a.created, 'en') : a.created.localeCompare(b.created, 'en')
        )
    }
    else if (this.state.currentSortMode === 'percentChange') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'price_change_percent'));
    }
    else if (this.state.currentSortMode === 'dollarChange') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'price_change'));
    }
    else if (this.state.currentSortMode === 'price') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'price'));
    }
    else if (this.state.currentSortMode === 'volume') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'volume_raw'));
    }
    else if (this.state.currentSortMode === 'spread') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'spread'));
    }
    else if (this.state.currentSortMode === 'marketCap') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'market_cap_raw'));
    }
    else if (this.state.currentSortMode === 'shares') {
        configs.sort((a,b) => this.compareTickerNumber(a, b, 'shares_outstanding_raw'));
    }

    return configs
  }
  
  render() {
    if (!this.context.authed) {
        return <>
          <PageTransition pageKey='stocksPage'>
              <div className='stocks-container'>
                <div className='not-authed'>You must be logged in to use this app.</div>
              </div>
          </PageTransition>
        </>
    }

    let allTickersData = {};
    let sortedTickerConfigs = [];
    if (this.state.stocksConfig && this.state.stocksData && this.state.stocksData.tickers) {
        allTickersData = this.state.stocksData.tickers;
        sortedTickerConfigs = this.getSortedTickerConfigs();
    }
    
    // Main stocks data pane. Add ticker form, price data, etc
    let mainOutput = null;
    if (this.state.stocksConfig && this.state.stocksData) {
        if (this.state.stocksData.fetching_disabled) {
            mainOutput = <div className='stocks-pane'>
                <div className='app-disabled-warning'>This app is temporarily disabled. Please check back later.</div>
            </div>;
        }
        else {
            let docTitleEntries = [];
            let tickersDataOutput = [];
            for (const config of sortedTickerConfigs) {
                let summary = null;
                let marketStats = null;
                let extraDetails = null;
                let ticker = config.ticker;
                let tickerData = allTickersData[ticker];
                
                if (!tickerData) {
                    summary = <>
                        <div className='ticker-fetching'>Fetching data...</div>
                    </>
                }
                else {
                    if (config.show_in_title) {
                        if (config.title_format) {
                            let entry = config.title_format;
                            entry = entry.replace(/\{\$\}/g, tickerData.price || '---')
                            entry = entry.replace(/\{volume\}/g, tickerData.volume || '---')
                            docTitleEntries.push(entry)
                        }
                        else {
                            docTitleEntries.push(`${ticker} ${tickerData.price}`)
                        }
                    }
                    
                    // Price alerts for each ticker.
                    // Notify if the current price moves outside of the latest alert price +- the alert_step threshold.
                    let alertOutput = null;
                    let alertHint = '(no alert)';
                    if (!this.synth) {
                        alertHint = '(alerts not supported)'
                    }
                    else if (config.alert) {
                        let alertStep = Number(config.alert_step);
                        
                        // If we can, compare our latest alerted price against the threshold.
                        if (Object.keys(this.latestAlertPrices).indexOf(ticker) !== -1) {
                            if (tickerData.price !== null) {
                                if (tickerData.price >= this.latestAlertPrices[ticker] + alertStep || tickerData.price <= this.latestAlertPrices[ticker] - alertStep) {
                                    if (alertStep === 0) this.latestAlertPrices[ticker] = round2f(tickerData.price);
                                    else this.latestAlertPrices[ticker] = round2f(Math.round(tickerData.price / alertStep ) * alertStep);
                                    
                                    // Play alert sound if supported
                                    if (this.synth && config.alert_speech && (tickerData.price || this.latestAlertPrices[ticker])) {
                                        if (!this.voices.length) this.voices = this.synth.getVoices();
                                        
                                        let msgText = config.alert_speech;
                                        msgText = msgText.replace(/\{\$\}/g, this.latestAlertPrices[ticker] || ' ')
                                        msgText = msgText.replace(/\{volume\}/g, tickerData.volume || ' ')
                                        let msg = new SpeechSynthesisUtterance(msgText);
                                        msg.lang = 'en-US'
                                        msg.voice = this.voices.find((voice) => voice.lang === 'en-US')
                                        this.synth.speak(msg);
                                    }
                                    
                                }
                            }
                        }
                        // If we dont have an alert price yet, set it. This just happens the first render usually
                        else if (tickerData.price !== null) {
                            if (alertStep === 0) this.latestAlertPrices[ticker] = round2f(tickerData.price);
                            else this.latestAlertPrices[ticker] = round2f(Math.round(tickerData.price / alertStep ) * alertStep);
                        }
                        
                        // Alert hint
                        if (Object.keys(this.latestAlertPrices).indexOf(ticker) !== -1) {
                            if (isNaN(this.latestAlertPrices[ticker])) { 
                                alertHint = `(alert error)`
                            }
                            else {
                                let min = this.latestAlertPrices[ticker] - alertStep;
                                let max = this.latestAlertPrices[ticker] + alertStep;
                                alertHint = `(alert ${min.toFixed(2)} - ${max.toFixed(2)})`
                            }
                        }
                    }
                    if (this.state.stocksConfig.show_ticker_alert_hints) {
                        alertOutput = <div className='alert'>{alertHint}</div>
                    }
                    
                    // Main ticker summary. Includes price, change dollars and percent, and alert hint
                    summary = <>
                        <div className={`ticker-current-price ${this.get_colour_class(tickerData.price_change)}`}>
                            <div className='current-price'>{tickerData.price}</div>
                            <div className='price-changes'>
                                <span className='change-dollars'>{tickerData.price_change}</span>
                                <span className='change-percent'>{tickerData.price_change_percent} %</span>
                            </div>
                            {alertOutput}
                        </div>
                    </>
                    
                    // Per ticker market key stats. Open/close/volume etc
                    if (this.state.stocksConfig.show_ticker_key_stats) {
                        let factoredPrice = '-';
                        if (config.price_factor && tickerData.price) {
                            factoredPrice = Number(config.price_factor) * Number(tickerData.price);
                            factoredPrice = isNaN(factoredPrice) ? '-' : factoredPrice.toFixed(2);
                        }
                        marketStats = <div className='market-stats'>
                            <div className='market-stats-item half'><span>Shares</span><span>{tickerData.shares_outstanding}</span></div>
                            <div className='market-stats-item half'><span>MktCap</span><span>{tickerData.market_cap}</span></div>
                            <div className='market-stats-item half'><span>Open</span><span>{tickerData.open}</span></div>
                            <div className='market-stats-item half'><span>{tickerData.data_format === 'regular' ? 'Prev close' : 'Close'}</span><span>{tickerData.close}</span></div>
                            <div className='market-stats-item half'><span>Volume</span><span>{tickerData.volume}</span></div>
                            <div className='market-stats-item half'>
                                {config.price_factor === '' ? ' ' : (
                                    <>
                                        <span>$ * {Number(config.price_factor).toFixed(2)}</span>
                                        <span>{factoredPrice}</span>
                                    </>
                                )}
                            </div>
                            <div className='market-stats-item full'>
                                <span>Range</span><span>{tickerData.low} - {tickerData.high} ({tickerData.spread} spread)</span>
                            </div>
                        </div>
                    }
                    
                    // Per ticker extra details. Company name, exchange, currency, etc
                    if (this.state.stocksConfig.show_ticker_extra_details) {
                        extraDetails = <div className='extra-details'>
                            <div className='company-name'>{tickerData.short_name}</div>
                            <div className='exchange-info'>{tickerData.quote_type} - {tickerData.currency} - {tickerData.exchange_name}</div>
                        </div>
                    }
                    
                }
                
                tickersDataOutput.push( 
                    <div key={'ticker-entry-'+ticker} className='ticker-entry'>
                        <div className='ticker'>{ticker}</div>
                        <div className='summary'>{summary}</div>
                        {marketStats}
                        {extraDetails}
                    </div>
                )
                
            }
            if (docTitleEntries.length) document.title = docTitleEntries.join(', ');
            else document.title = 'Mike';
            
            // Displays market state: pre, post, regular, closed
            let marketStateOutput = null;
            if (this.state.stocksConfig.show_market_state) {
                marketStateOutput = <div className='market-state'>
                    <span>Market:</span>
                    <span>{this.state.stocksData ? this.state.stocksData.market_state : '---'}</span>
                </div>
            }
            
            // Market countdown. Shows time left until market opens or closes
            let countdownOutput = null;
            if (this.state.stocksConfig.show_regular_market_countdown) {
                let totalSeconds, verb;
                
                let curTime = new Date().getTime() / 1000;
                if (curTime < this.nextMarketOpenTS) {
                    // Counting down towards when the market opens
                    totalSeconds = this.nextMarketOpenTS - curTime;
                    verb = 'opens';
                }
                else {
                    // Counting down towards when the market closes
                    totalSeconds = (this.nextMarketOpenTS + 23400) - curTime;
                    verb = 'closes';
                }
                
                let totalMinutes = totalSeconds / 60;
                let totalHours = totalMinutes / 60;
                let totalDays = totalHours / 24;
                
                countdownOutput = <div className='market-countdown'>
                    Market {verb} in {Math.floor(totalDays)}<span>d</span> {Math.floor(totalHours % 24)}<span>h</span> {Math.floor(totalMinutes % 60)}<span>m</span> {Math.floor(totalSeconds % 60)}<span>s</span>
                </div>
            }
            
            // Displays latest timestamp the price data was updated
            let timestampOutput = null;
            if (this.state.stocksConfig.show_timestamp) {
                timestampOutput = <div
                    id='timestamp-display'
                    className='updated-ts'
                    onClick={() => window.scrollTo({
                        top: document.getElementById('timestamp-display').offsetTop - 8,
                        behavior: 'smooth',
                    })}>
                    {this.state.stocksData ? this.state.stocksData.timestamp : '---'}
                </div>
            }
            
            // Displays the ticker sorting menu
            let sortingMenuOutput = <div className={'sort-menu ' + (this.state.sortMenuVisible ? 'visible' : 'hidden')}>
                {Object.keys(this.sortModes).map((key) => 
                    <div key={'sort-option-'+key} className='sort-option' onClick={() => {
                        let reverse = false;
                        if (this.state.currentSortMode === key) reverse = !this.state.currentSortReverse
                        this.setState({
                            sortMenuVisible: false,
                            currentSortMode: key,
                            currentSortReverse: reverse,
                        });
                    }}>
                        <div className='sort-label'>{this.sortModes[key]}</div>
                        <div className='indicator'>
                            {this.state.currentSortMode === key && 
                            (this.state.currentSortReverse ? <UpArrowIcon className='selected-icon'/> : <DownArrowIcon className='selected-icon'/>)}
                        </div>
                    </div>
                )}
            </div>

            let isRegMarket = this.state.stocksData && this.state.stocksData.market_state === 'regular';
            
            // Main stocks pane output. Includes all the pieces. Form, summary, tickers, gear button
            mainOutput = <motion.div className='stocks-pane' initial={false} animate={this.state.configMode ? 'paneHidden' : 'paneVisible'} variants={this.variants}>
                <motion.div className={'ticker-add-form ' + (this.state.addTickerEnabled ? 'enabled' : 'disabled')} initial={false} animate={this.state.addTickerEnabled ? 'addFormEnabled' : 'addFormDisabled'} variants={this.variants}>
                    <div className='form-field'>
                        <div className='input-container'>
                            <input 
                                className='add-ticker-input'
                                id='add-ticker-value'
                                type='text'
                                name='add-ticker-value'
                                value={ this.state.addTickerValue }
                                placeholder='ADD TICKER'
                                onChange={ (e) => this.setState({addTickerValue: e.target.value.toUpperCase(), addTickerError: null}) }
                                onKeyPress={ (e) => {if (e.key === 'Enter' || e.keyCode === 13) this.postAddTicker()} }
                                disabled={this.state.addTickerEnabled ? '' : ''}
                                maxLength='24'
                            />
                        </div>
                        { this.state.addTickerError ? (
                            <div className='field-error'>{ this.state.addTickerError }</div>
                        ) : null }
                    </div>
                </motion.div>
                
                {this.state.stocksData.has_tickers ? (
                    <>
                        {marketStateOutput}
                        {countdownOutput}
                        {timestampOutput}
                        <div className='market-stats-title'>{isRegMarket ? 'Current market stats' : 'Latest regular market stats'}</div>
                        {sortingMenuOutput}
                        <motion.div whileHover={{scale: 1.3, transition: { duration: 0.3 }}} whileTap={{ scale: 0.9 }} className='sort-btn' onClick={() => {
                            this.setState({sortMenuVisible: !this.state.sortMenuVisible});
                        }}>
                            <SortIcon className='inner-icon'/>
                        </motion.div>
                        <motion.div whileHover={{scale: 1.2, rotate: 60, transition: { duration: 0.3 }}} whileTap={{ scale: 0.9 }} className='config-btn' onClick={() => {
                            this.setState({configMode: !this.state.configMode});
                        }}>
                            <GearIcon className='inner-icon'/>
                        </motion.div>
                        <div className='tickers-data'>{tickersDataOutput}</div>
                    </>
                ) : (
                    <div className='tickers-empty'>
                        You haven't added any tickers yet!
                        <br/><br/>
                        Type a symbol in the "ADD TICKER" field, press enter and wait a few seconds.
                    </div>
                )}
            </motion.div>
        }
    }
    else {
        mainOutput = <div className='stocks-pane'>
            <Spinner spinnerType='pacman' />
            <div className='loading'>Loading...</div>
        </div>;
    }
    
    
    
    // Config editor pane
    let generalConfig = null;
    let tickerConfigs = [];
    if (this.state.stocksConfig) {
        for (const config of sortedTickerConfigs) {
            const ticker = config.ticker
            // console.log('config loop', ticker, this.state[`form-config-ticker-${ticker}-alert`])
            
            let alertSupported = Boolean(this.synth);
            let alertEnabled = this.state[`form-config-ticker-${ticker}-alert`];
            let showInTitleEnabled = this.state[`form-config-ticker-${ticker}-show_in_title`];
            
            let tickerConfigOutput = null;
            if (this.state.configMode) {
                 tickerConfigOutput = <div key={'ticker-config-section-'+ticker} className='ticker-config-section'>
                    <div className='ticker-title'>{ticker}</div>
                    
                    <div className='config-input config-input-checkbox'>
                        <input 
                            id={`ticker-config-${ticker}-show_in_title`}
                            type='checkbox'
                            name={`form-config-ticker-${ticker}-show_in_title`}
                            checked={ this.state[`form-config-ticker-${ticker}-show_in_title`] }
                            onChange={ (e) => this.setState({[`form-config-ticker-${ticker}-show_in_title`]: e.target.checked}) }
                        />
                        <motion.label htmlFor={`ticker-config-${ticker}-show_in_title`} whileTap={{ scale: 0.95 }}>
                            <span className='checkbox-label'>Show in tab title</span>
                            <Checkmark className='checkbox-box'/>
                        </motion.label>
                    </div>
                    
                    <div className={'form-field config-input config-input-text' + (showInTitleEnabled ? '' : ' disabled')}>
                        <label htmlFor={`ticker-config-${ticker}-title_format`}>
                            Tab title format
                        </label>
                        <input 
                            id={`ticker-config-${ticker}-title_format`}
                            type='text'
                            name={`form-config-ticker-${ticker}-title_format`}
                            value={ this.state[`form-config-ticker-${ticker}-title_format`] }
                            onChange={ (e) => this.setState({[`form-config-ticker-${ticker}-title_format`]: e.target.value}) }
                            disabled={showInTitleEnabled ? '' : '1'}
                            maxLength='128'
                        />
                    </div>
                    
                    <div className={'config-input config-input-checkbox' + (alertSupported ? '' : ' disabled')}>
                        <input 
                            id={`ticker-config-${ticker}-alert`}
                            type='checkbox'
                            name={`form-config-ticker-${ticker}-alert`}
                            checked={ alertEnabled }
                            onChange={ (e) => this.setState({[`form-config-ticker-${ticker}-alert`]: e.target.checked}) }
                            disabled={alertSupported ? '' : '1'}
                        />
                        <motion.label htmlFor={`ticker-config-${ticker}-alert`} whileTap={{ scale: alertSupported ? 0.95 : 1 }}>
                            <span className='checkbox-label'>Price change alerts</span>
                            <Checkmark className='checkbox-box'/>
                        </motion.label>
                    </div>
                    
                    <div className={'form-field config-input config-input-text' + (alertSupported && alertEnabled ? '' : ' disabled')}>
                        <label htmlFor={`ticker-config-${ticker}-alert_step`}>Alert increment</label>
                        <input 
                            id={`ticker-config-${ticker}-alert_step`}
                            type='text'
                            name={`form-config-ticker-${ticker}-alert_step`}
                            value={ this.state[`form-config-ticker-${ticker}-alert_step`] }
                            onChange={ (e) => this.setState({[`form-config-ticker-${ticker}-alert_step`]: e.target.value}) }
                            disabled={alertSupported && alertEnabled ? '' : '1'}
                            maxLength='6'
                        />
                    </div>
                    
                    <div className={'form-field config-input config-input-text' + (alertSupported && alertEnabled ? '' : ' disabled')}>
                        <label htmlFor={`ticker-config-${ticker}-alert_speech`}>
                            Alert speech <span className={'speaker-icon' + (this.voices.length ? '' : ' disabled')} onClick={(e) => {
                                e.preventDefault();
                                if (this.voices.length) this.testSpeech(ticker);
                            }}><SpeakerIcon/></span>
                        </label>
                        <input 
                            id={`ticker-config-${ticker}-alert_speech`}
                            type='text'
                            name={`form-config-ticker-${ticker}-alert_speech`}
                            value={ this.state[`form-config-ticker-${ticker}-alert_speech`] }
                            onChange={ (e) => this.setState({[`form-config-ticker-${ticker}-alert_speech`]: e.target.value}) }
                            disabled={alertSupported && alertEnabled ? '' : '1'}
                            maxLength='64'
                        />
                    </div>
                    
                    <div className='form-field config-input config-input-text'>
                        <label htmlFor={`ticker-config-${ticker}-price_factor`}>Price factor</label>
                        <input 
                            id={`ticker-config-${ticker}-price_factor`}
                            type='text'
                            name={`form-config-ticker-${ticker}-price_factor`}
                            value={ this.state[`form-config-ticker-${ticker}-price_factor`] }
                            onChange={ (e) => this.setState({[`form-config-ticker-${ticker}-price_factor`]: e.target.value}) }
                            maxLength='5'
                        />
                    </div>
                    
                    <motion.div className='remove-ticker-btn' onClick={() => this.postRemoveTicker(ticker)} whileHover={{ scale: 1.1, opacity: 1 }} whileTap={{ scale: 1, opacity: 1 }}>
                        Remove {ticker}
                    </motion.div>
                </div>
            }
            
            tickerConfigs.push(tickerConfigOutput);
        }
        
        generalConfig = <>
            <div className='config-input config-input-checkbox'>
                <input 
                    id='general-config-show_market_state'
                    type='checkbox'
                    name='form-config-general-show_market_state'
                    checked={ this.state['form-config-general-show_market_state'] }
                    onChange={ (e) => this.setState({'form-config-general-show_market_state': e.target.checked}) }
                />
                <motion.label htmlFor='general-config-show_market_state' whileTap={{ scale: 0.95 }}>
                    <span className='checkbox-label'>Show market state</span>
                    <Checkmark className='checkbox-box'/>
                </motion.label>
            </div>
            
            <div className='config-input config-input-checkbox'>
                <input 
                    id='general-config-show_timestamp'
                    type='checkbox'
                    name='form-config-general-show_timestamp'
                    checked={ this.state['form-config-general-show_timestamp'] }
                    onChange={ (e) => this.setState({'form-config-general-show_timestamp': e.target.checked}) }
                />
                <motion.label htmlFor='general-config-show_timestamp' whileTap={{ scale: 0.95 }}>
                    <span className='checkbox-label'>Show timestamp</span>
                    <Checkmark className='checkbox-box'/>
                </motion.label>
            </div>
            
            <div className='config-input config-input-checkbox'>
                <input 
                    id='general-config-show_regular_market_countdown'
                    type='checkbox'
                    name='form-config-general-show_regular_market_countdown'
                    checked={ this.state['form-config-general-show_regular_market_countdown'] }
                    onChange={ (e) => this.setState({'form-config-general-show_regular_market_countdown': e.target.checked}) }
                />
                <motion.label htmlFor='general-config-show_regular_market_countdown' whileTap={{ scale: 0.95 }}>
                    <span className='checkbox-label'>Show market countdown</span>
                    <Checkmark className='checkbox-box'/>
                </motion.label>
            </div>
            
            <div className='config-input config-input-checkbox'>
                <input 
                    id='general-config-show_ticker_key_stats'
                    type='checkbox'
                    name='form-config-general-show_ticker_key_stats'
                    checked={ this.state['form-config-general-show_ticker_key_stats'] }
                    onChange={ (e) => this.setState({'form-config-general-show_ticker_key_stats': e.target.checked}) }
                />
                <motion.label htmlFor='general-config-show_ticker_key_stats' whileTap={{ scale: 0.95 }}>
                    <span className='checkbox-label'>Show ticker key stats</span>
                    <Checkmark className='checkbox-box'/>
                </motion.label>
            </div>
            
            <div className='config-input config-input-checkbox'>
                <input 
                    id='general-config-show_ticker_extra_details'
                    type='checkbox'
                    name='form-config-general-show_ticker_extra_details'
                    checked={ this.state['form-config-general-show_ticker_extra_details'] }
                    onChange={ (e) => this.setState({'form-config-general-show_ticker_extra_details': e.target.checked}) }
                />
                <motion.label htmlFor='general-config-show_ticker_extra_details' whileTap={{ scale: 0.95 }}>
                    <span className='checkbox-label'>Show ticker extra details</span>
                    <Checkmark className='checkbox-box'/>
                </motion.label>
            </div>
            
            <div className='config-input config-input-checkbox'>
                <input 
                    id='general-config-show_ticker_alert_hints'
                    type='checkbox'
                    name='form-config-general-show_ticker_alert_hints'
                    checked={ this.state['form-config-general-show_ticker_alert_hints'] }
                    onChange={ (e) => this.setState({'form-config-general-show_ticker_alert_hints': e.target.checked}) }
                />
                <motion.label htmlFor='general-config-show_ticker_alert_hints' whileTap={{ scale: 0.95 }}>
                    <span className='checkbox-label'>Show ticker alert hints</span>
                    <Checkmark className='checkbox-box'/>
                </motion.label>
            </div>
        </>
        
    }
    
    let configPaneOutput = <motion.div className='config-pane' initial={false} animate={this.state.configMode ? 'paneVisible' : 'paneHidden'} variants={this.variants}>
        <div className='config-title'>General settings</div>
        <div className='general-config'>{generalConfig}</div>
        <div className='config-title'>Individual settings</div>
        {this.synth ? null : (
            <div className='alerts-unsupported'>Note: your browser does not support alerts</div>
        )}
        <div className='ticker-configs'>{tickerConfigs.length > 0 ? tickerConfigs : (
            <div className='tickers-empty'>
                You have no active tickers!
                <br/><br/>
                Individual ticker settings will show up here once you start tracking a ticker.
            </div>
        )}</div>
    </motion.div>
    
    let configStickyFooter = <motion.div className='config-sticky-footer' initial={false} animate={this.state.configMode ? 'stickyFooterVisible' : 'stickyFooterHidden'} variants={this.variants}>
        <motion.div className='footer-btn cancel' whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => {
            this.resetConfigState(this.state.stocksConfig)
            this.setState({configMode: false})
        }}>CANCEL</motion.div>
        <motion.div className='footer-btn save' onClick={() => this.postConfigUpdate()} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>SAVE</motion.div>
    </motion.div>
    
    
    return (
      <PageTransition pageKey='stocksPage'>
          <div className='stocks-container'>
            {mainOutput}
            {configPaneOutput}
            {configStickyFooter}
        </div>
      </PageTransition>
    );
  }
}
